<template>
  <div class="page-layout">
    <el-breadcrumb separator="/" style="margin: 15px 0 25px;">
      <el-breadcrumb-item v-for="item in bread" :key="item.title" :to="getUrl(item)" :class="item.isParent ? 'first-title' : ''">
        {{ item.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['bread'])
  },
  methods: {
    getUrl(item) {
      if (item.isParent) {
        return
      }
      let obj = {}
      obj.path = item.path
      if (item.path === '/teacher/periodDetail/periodBar' || item.path === '/teacher/periodDetail/openBar') {
        obj.query = {
          id: this.$route.query.id
        }
      }
      return obj
    }
  }
}
</script>
<style lang="less" scoped>
.first-title {
  font-size: 15px;
  font-weight: 700;
}
</style>

